.accordion {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 10px;
  overflow: hidden;
}

.accordion-item {
  border-bottom: 1px solid #e0e0e0;
}

.accordion-item:last-child {
  border-bottom: none;
}

.accordion-header {
  background-color: #f5f5f5;
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-header h3 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.accordion-icon {
  font-size: 24px;
  transition: transform 0.3s ease;
}

.accordion-icon.open {
  transform: rotate(180deg);
}

.accordion-content {
  padding: 15px;
  background-color: #ffffff;
}

.accordion-trigger {
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-title {
  flex-grow: 1;
}
